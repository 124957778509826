import { Button, CircularProgress, Grid, Paper, TextField, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import * as Bluebird from 'bluebird';
import { Bind } from 'lodash-decorators';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { StatusCodeError } from '../../../utilities/agent';
import Cognito from '../../../utilities/cognito';


const WSLoginViewStyles = (theme: Theme) => ({
    form: {
        margin: theme.spacing(2),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        padding: theme.spacing(2),
    },
    root: {
        height: '100vh',
        width: '100vw',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    submitProgress: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    submitWrapper: {
        margin: theme.spacing(1),
        position: 'relative' as 'relative',
    },
    title: {
        alignSelf: 'center',
    },
});

type WSLoginViewProps = RouteComponentProps & WithStyles<typeof WSLoginViewStyles>;

interface IWSLoginViewState {
    step: 'otp' | 'userid';
    loading: boolean;
    OTP?: string;
    UserId?: string;
}

export class WSLoginView extends React.Component<WSLoginViewProps, IWSLoginViewState> {

    public constructor(props: WSLoginViewProps) {
        super(props);

        this.state = { step: 'userid', loading: false };
    }


    public render(): React.ReactNode {
        const { classes } = this.props;
        const { OTP, UserId, loading, step } = this.state;

        return (
            <Grid alignItems='center' className={ classes.root } component='main' container direction='row' justify='center'>
                <Grid item lg={ 3 } md={ 4 } sm={ 6 } xs={ 8 }>
                    <Paper className={ classes.paper }>
                        <Typography className={ classes.title } component='h1' variant='h5'>Sign in</Typography>
                        <form className={ classes.form } noValidate onSubmit={ this.onSubmitForm }>
                            <TextField autoComplete='email' autoCorrect='off' autoFocus={ (step === 'userid') } disabled={ ((step !== 'userid') || loading) } inputMode='email' inputProps={ { autoCapitalize: 'off' } } fullWidth label='Your e-mail' margin='normal' onChange={ this.onUsernameTextFieldChange } onKeyUp={ this.onKeyUp } required type='email' value={ UserId }/>
                            { ((step === 'otp') && (
                                <TextField autoComplete='one-time-code' autoCorrect='off' autoFocus={ (step === 'otp') } disabled={ ((step !== 'otp') || loading) } inputMode='decimal' inputProps={ { autoCapitalize: 'off' } } fullWidth label='Your OTP' margin='normal' onChange={ this.onOTPTextFieldChange } onKeyUp={ this.onKeyUp } required value={ OTP }/>
                            )) }
                            <div className={ classes.submitWrapper }>
                                <Button className={ classes.submit } color='primary' disabled={ loading } fullWidth onClick={ this.onButtonClick } variant='contained'>
                                    { (step === 'otp') ? 'Sign In' : 'Next' }
                                </Button>
                                { ((loading) && (<CircularProgress className={ classes.submitProgress } size={ 24 }/>)) }
                            </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }


    private authenticationBegin(): Bluebird<void> {
        const { history } = this.props;
        const { UserId } = this.state;

        return Cognito.attemptSignIn(UserId)
            .then((result) => {
                if (result.session) {
                    history.replace('/');
                } else if (result.challenge === 'otp') {
                    this.setState({ step: 'otp' }); 
                }
            })
    }

    private authenticationComplete(): Bluebird<void> {
        const { history } = this.props;
        const { OTP } = this.state;

        return Cognito.confirmSignIn(OTP)
            .then((result) => {
                if (result.session) {
                    history.replace('/');
                }
            });
    }

    @Bind
    private onKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === 'Enter') {
            event.preventDefault();

            this.onButtonClick(null);
        }
    }

    @Bind
    private onSubmitForm(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
    }

    @Bind
    private onButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        const { step } = this.state;

        Bluebird.try(() => {
            this.setState({ loading: true });

            if (step === 'otp') {
                this.authenticationComplete();
            } else {
                this.authenticationBegin();
            }
        })
            .catch(StatusCodeError, (error) => {
                // if ((error.code >= 400) && (error.code < 405)) {
                //     Alert.alert('Login error', 'An error occurred while loading your informations, please check your username and try again.');
                // } else {
                    throw error;
                // }
            })
            .catch((error) => {
                // Alert.alert('Login error', 'An error occurred while loading your informations, please try again.');
            })
            .finally(() => { this.setState({ loading: false }); });
    }

    @Bind
    private onOTPTextFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ OTP: event.target.value });
    }

    @Bind
    private onUsernameTextFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ UserId: event.target.value });
    }

}

export default withStyles(WSLoginViewStyles, { withTheme: true })(withRouter(WSLoginView));
