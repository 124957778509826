import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import Loadable from '@loadable/component'
import * as React from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import WSLoginView from './content/auth/WSLoginView';
import WSAuthenticatedRoute from './router/WSAuthenticatedRoute';
import WSUnauthenticatedRoute from './router/WSUnauthenticatedRoute';


const WSAppBar = Loadable(() => import('./common/WSAppBar'));
const WSContent = Loadable(() => import('./common/WSContent'));
const WSDrawer = Loadable(() => import('./common/WSDrawer'));


const WSAppStyles = (theme: Theme) => ({
    app: {
        height: '100vh',
        width: '100vw',
    },
});

type WSAppProps = RouteComponentProps & WithStyles<typeof WSAppStyles>;

export class WSApp extends React.Component<WSAppProps> {

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <Switch>
                <WSUnauthenticatedRoute exact path='/auth'>
                    <WSLoginView/>
                </WSUnauthenticatedRoute>
                <WSAuthenticatedRoute path='/'>
                    <Grid className={ classes.app }>
                        <WSAppBar/>
                        <WSDrawer/>
                        <WSContent/>
                    </Grid>
                </WSAuthenticatedRoute>
            </Switch>
        );
    }

}

export default withStyles(WSAppStyles, { withTheme: true })(withRouter(WSApp));
