import * as Bluebird from 'bluebird';
import * as Mime from 'mime';


export enum Content {
    Json  = 'json',
    Plain = 'plain',
}

export enum Method {
    Connect = 'CONNECT',
    Delete  = 'DELETE',
    Get     = 'GET',
    Head    = 'HEAD',
    Options = 'OPTIONS',
    Patch   = 'PATCH',
    Post    = 'POST',
    Put     = 'PUT',
    Trace   = 'TRACE',
}


interface ICommonOptions {
    headers?: HeadersInit;
}

export interface IAgentOptions extends ICommonOptions {
    baseUri: string;
}

interface IRequestOptions<C extends Content, S extends any> extends ICommonOptions {
    content?: C;
    keepalive?: boolean;
    method: Method;
    qs?: { [parameter: string]: string }
    serializationOptions?: S;
}
type RequestWithoutBodyOptions =
    IRequestOptions<Content.Json, never> |
    IRequestOptions<Content.Plain, never>;

interface IRequestWithBodyOptions<C extends Content, S extends any, D extends any> extends IRequestOptions<C, S> {
    body?: any;
    deserializationOptions?: D;
}
type RequestWithBodyOptions = 
    IRequestWithBodyOptions<Content.Json, never, never> |
    IRequestWithBodyOptions<Content.Plain, never, never>;


export default class Agent {

    public static request<R = any>(uri: string, options: RequestWithBodyOptions): Bluebird<R> {
        let response: Response;

        return Bluebird.try(() => {
            const headers: Headers = new Headers(options.headers);
            const mime: string = Mime.getType(options.content)

            let body: string;

            if ((options.content) && (mime)) {
                headers.set('Accept', mime);
            }

            if (options.body) {
                if (options.content === Content.Json) {
                    body = JSON.stringify(options.body);
                } else {
                    body = options.body;
                }
                if (mime) {
                    headers.set('Content-Type', mime);
                }
            }

            const url = new URL(uri);
            if (options.qs) {
                for (const key in options.qs) {
                    url.searchParams.set(key, options.qs[key]);
                };
            }

            return Bluebird.resolve(fetch(url.href, {
                body,
                headers,
                keepalive: options.keepalive,
                method: options.method,
            }));
        })
            .then((result) => {
                response = result;

                return Bluebird.resolve(result.text());
            })
            .then((result) => {
                if (result) {
                    if (options.content === Content.Json) {
                        return JSON.parse(result);
                    } else {
                        return result;
                    }
                }
            })
            .tap((result) => {
                if (!response.ok) {
                    if (result.message) {
                        throw new StatusCodeError(result.message, response.status);
                    }

                    throw new StatusCodeError(response.statusText, response.status);
                }
            });
    }


    public constructor(public readonly defaults: IAgentOptions) { }


    public request<R = any>(path: string, request: RequestWithBodyOptions): Bluebird<R> {
        return Bluebird.try(() => {
            const { baseUri, headers: defaultHeaders, ...defaultOptions } = this.defaults;
            const { headers: requestHeaders, ...requestOptions } = request;

            const mergedOptions: RequestWithoutBodyOptions = { ...defaultOptions, ...requestOptions };
            const headers = (defaultHeaders) ? new Headers(defaultHeaders) : new Headers();
            if (requestHeaders) {
                const tmpHeaders = new Headers(requestHeaders);
                tmpHeaders.forEach((key, value) => {
                    headers.set(key, value);
                });
            }
            mergedOptions.headers = headers;

            const url = new URL(path, this.defaults.baseUri);

            return Agent.request<R>(url.href, mergedOptions);
        });
    }


    public delete<R = any>(path: string, request: Omit<RequestWithoutBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Delete } as RequestWithoutBodyOptions);
    }

    public get<R = any>(path: string, request: Omit<RequestWithoutBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Get } as RequestWithoutBodyOptions);
    }

    public head<R = any>(path: string, request: Omit<RequestWithoutBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Head } as RequestWithoutBodyOptions);
    }

    public patch<R = any>(path: string, request: Omit<RequestWithBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Patch } as RequestWithBodyOptions);
    }

    public post<R = any>(path: string, request: Omit<RequestWithBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Post } as RequestWithBodyOptions);
    }

    public put<R = any>(path: string, request: Omit<RequestWithBodyOptions, 'method'>): Bluebird<R> {
        return this.request(path, { ...request, method: Method.Put } as RequestWithBodyOptions);
    }

}


export class StatusCodeError extends Error {

    constructor(message: string, public readonly code: number) {
        super(message);

        this.name = 'StatusCodeError';
    }

}
