import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import Cognito from '../../utilities/cognito';


export default class WSAuthenticatedRoute extends React.Component<RouteProps> {

    public render(): React.ReactNode {
        const { children, ...rest } = this.props;

        return <Route { ...rest } render={ (props) => {
            if (Cognito.isSignedIn) {
                return children;
            } else {
                return <Redirect to='/auth'/>
            }
        } }/>;
    }

}
