import MomentUtils from '@date-io/moment';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as moment from 'moment-timezone';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-roboto';

import WSApp from './components/WSApp';
import Cognito from './utilities/cognito';
import { theme } from './theme';


moment.tz.guess(true);

Cognito.configure()
  .finally(() => {
    ReactDOM.render((
      <MuiPickersUtilsProvider utils={ MomentUtils }>
          <ThemeProvider theme={ theme }>
            <CssBaseline/>
            <BrowserRouter>
              <WSApp/>
            </BrowserRouter>
          </ThemeProvider>
      </MuiPickersUtilsProvider>
    ), document.querySelector('#root'));
  });
