import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import Bluebird from 'bluebird';

import { cognitoClientId, cognitoUserPoolId } from './constants';


const userPoolClient = new CognitoUserPool({
  ClientId: cognitoClientId,
  UserPoolId: cognitoUserPoolId,
});


let user: CognitoUser;
let session: CognitoUserSession;


export default class Cognito {

  public static get isSignedIn() { return (session) ? session.isValid() : false; }


  public static configure(): Bluebird<CognitoUserSession> {
      return Bluebird.try(() => {
        user = userPoolClient.getCurrentUser();

        if (user) {
          return Bluebird.fromCallback<CognitoUserSession>((callback) => user.getSession(callback));
        }
      })
      .then((result) => {
        session = result;

        return session;
      })
      .catch((error) => null);
  }

  public static attemptSignIn(login: string): Bluebird<{ challenge?: any, session?: CognitoUserSession}> {
    return Bluebird.try(() => {
      user = new CognitoUser({
        Pool: userPoolClient,
        Username: login,
      });

      const authenticationDetails = new AuthenticationDetails({
        Username: login,
      });

      return this.authenticate({ authenticationDetails });
    });
  }

  public static confirmSignIn(code: string): Bluebird<{ challenge?: any, session?: CognitoUserSession}> {
    return Bluebird.try(() => {
      if (user != null) {
        return this.authenticate({ challengeAnswer: code })
      } else {
        throw Error('Invalid session');
      }
    });
  }

  public static signOut(): Bluebird<void> {
    return Bluebird.try(() => {
      if (user != null) {
        return Bluebird.fromCallback<void>((callback) => user.signOut(() => callback(null)));
      }
    })
    .then(() => {
      user = null;
      session = null;
    });
  }


  public static getToken(): Bluebird<CognitoUserSession> {
    return Bluebird.try(() => {
      if ((session) && (!session.isValid())) {
        const refreshToken = session.getRefreshToken();

        if (refreshToken) {
          return Bluebird.fromCallback<void>((callback) => user.refreshSession(refreshToken, callback));
        }
      }
    })
      .then(() => {
        if (session) {
          return session.getIdToken().getJwtToken();
        }
      })
      .catch((error) => null);
  }


  private static authenticate(options: { authenticationDetails?: AuthenticationDetails, challengeAnswer?: any } = { }): Bluebird<{ challenge?: any, session?: CognitoUserSession }> {
    return Bluebird.try(() => {
      const { authenticationDetails, challengeAnswer } = options;

      if (!!authenticationDetails !== !!challengeAnswer) {
        return new Bluebird<{ challenge?: any, session?: CognitoUserSession }>((resolve, reject) => {
          if (authenticationDetails) {
            user.initiateAuth(authenticationDetails, {
              customChallenge: ({ challenge }) => resolve({ challenge }),
              onFailure: reject,
              onSuccess: (session) => resolve({ session }),
            });
          } else if (challengeAnswer) {
            user.sendCustomChallengeAnswer(challengeAnswer, {
              customChallenge: ({ challenge }) => resolve({ challenge }),
              onFailure: reject,
              onSuccess: (session) => resolve({ session }),
            });
          }
        });
      }

      throw Error('Invalid arguments');
    })
      .tap((result) => {
        if (result.session) {
          session = result.session;
        }
      });
  }

}
