import { createMuiTheme } from '@material-ui/core';


const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: '#FFF',
            dark: '#752127',
            light: '#E9573D',
            main: '#C21B17',
        },
        secondary: {
            contrastText: '#000',
            dark: '#B15C49',
            light: '#F09273',
            main: '#D16944',
        },
        error: {
            light: '#B65A65',
            main: '#8E1230',
        },
        background: {
            default: '#FFF',
        },
    },
});

export { theme };
